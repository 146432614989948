export default {

  // Application name which is fixed in <head>
  appTitle: 'Positive Access Point',
  appShortTitle: 'Access Point',
  appDescription: 'Nástroj na prepojenie Vášho informačného systému s partnerskými systémami',
  appLogoAlt: 'Positive Access Point',

  // Application run
  appRunInProgress: 'Čakajte prosím, spúšťa sa aplikácia',
  appRunError: 'Chyba počas spustenia, skúste znovu!',

  // Application texts on UI
  uiSiteName: 'Positive Access Point',
  uiCopyright: 'Všetky práva sú vyhradené. Positive s.r.o.',
  uiFooterContent: '',

  // Login page
  loginTitle: 'Prihlásenie',
  loginButtonValue: 'Prihlásiť sa',

  // Callback page
  loginPendingTitle: 'Prebieha prihlásenie',
  loginInProgressDescription: 'Čakajte prosím, spúšťa sa aplikácia...',

  successLoginFlash: 'Úspešné prihlásenie',
  successLoginDescription: '<strong>Úspešne ste sa prihlásili.</strong><br />Budete presmerovaný/á.',

  errorLoginTitle: 'Chyba počas prihlásenia',
  errorLoginDescription: '<strong>Chyba počas prihlásenia.</strong><br />Skúste znovu!',
  errorLoginCatch: '<strong>Autorizačný server neschválil prístup.</strong><br />Skúste znovu!',
  errorLoginRetryBtn: 'Skúsiť znovu',

  // Logout page
  successLogoutTitle: 'Úspešné odhlásenie',
  errorLogoutTitle: 'Neúspešne odhlásenie',
  successLogoutDescription: 'Úspešne ste sa odhlásili zo služby Positive Access Point. Za chvíľu budete presmerovaný/á na prihlasovacie okno.',
  errorLogoutDescription: 'Chyba počas odhlásenia. Skúsime znovu, budete presmerovaný/á.',

  // Home page
  myAppsTitle: 'Moje prepojenia',
  searchApps: 'Vyhľadávať v zozname mojich applikácií',
  allMyApps: 'Všetky prepojenia',
  inThisGroupNoApps: 'V tejto skupine nie sú žiadne prepojenia',
  noSearchResultsInApps: 'Nič nevyhovuje Vašim vyhľadávacím kritériám',

  // My profile page
  myProfileTitle: 'Môj profil',
  myProfile: 'Môj profil',
  profileSectionUserTitle: 'Základné údaje',
  profileSectionPasswordTitle: 'Zmena hesla',
  profileSectionPasswordDescription: 'Vyplňte iba v prípade, ak si chcete zmeniť heslo!',
  profileSectionUserdataTitle: 'Údaje o používateľovi',
  profileSectionCorespTitle: 'Korešpondenčné údaje',
  profileSectionIdentifierTitle: 'Identifikačné údaje',
  profileSectionAdditionalTitle: 'Doplnkové údaje',
  profileUpdate: 'Uložiť zmeny',

  'profileFix-company': 'Firma',
  'profileFix-username': 'Používateľské meno',
  'profileFix-email': 'E-mail',

  'profileFix-password': 'Heslo',
  'profileFix-password_repeat': 'Heslo znovu',

  'profile-username': 'Username',
  'profile-nbs_num': 'Číslo v registri NBS',
  'profile-personal_num': 'Osobné číslo',
  'profile-email': 'E-mail',
  'profile-account_type': 'Typ účtu',
  'profile-user_ip_address': 'IP adresa',
  'profile-firstname': 'Meno',
  'profile-lastname': 'Priezvisko',
  'profile-phone': 'Telefón',
  'profile-house_number': 'Číslo domu',
  'profile-street': 'Ulica',
  'profile-city': 'Mesto/Obec',
  'profile-zip': 'PSČ',
  'profile-country': 'Štát',
  'profile-notes': 'Poznámky',

  'profile-user_type': 'Typ používateľa',
  'profile-corresp_firstname': 'Meno',
  'profile-corresp_lastname': 'Priezvisko',
  'profile-corresp_house_number': 'Číslo domu',
  'profile-corresp_street': 'Ulica',
  'profile-corresp_city': 'Mesto/Obec',
  'profile-corresp_zip': 'PSČ',
  'profile-corresp_country': 'Štát',

  'profile-employee_number': 'Číslo zamestnanca',
  'profile-internal_number': 'ID v externom systéme',
  'profile-personal_number': 'Osobné číslo',
  'profile-nbs_number': 'NBS číslo',
  'profile-birth_number': 'Rodné číslo',

  'profile-company_name': 'Názov spoločnosti',
  'profile-agent_type': 'Typ agenta',
  'profile-icm2_broker_id': 'ID brokera v ICM',
  'profile-icm2_user_id': 'ID používateľa v ICM',
  'profile-internal_number_special': 'Špeciálne interné číslo',


  'profileRole-notCorrect': 'Zvolené heslo nie je dostatočne komplexné! Musí obsahovať malé pismeno, veľké písmeno, číslo a jeden špeciálny znak.',
  'profileRole-notEqual': 'Zadané heslá musia byť rovnaké',

  'profileForm-saved': 'Vaše údaje boli úspešne uložené',

  fileIsAttached: 'Súbor je priradený',
  fileIsNotAttached: 'Súbor ešte nebol priradený',

  // Commonly used words
  search: 'Vyhľadávanie',
  add: 'Pridať',
  close: 'Zatvoriť',
  cancel: 'Zrušiť',
  save: 'Uložiť',
  reset: 'Reset',
  edit: 'Editovať',
  delete: 'Odstrániť',
  reload: 'Načítať znovu',
  loadingAlt: 'Načítava sa...',
  default: 'Prednastavené',
  yes: 'Áno',
  no: 'Nie',
  showDetails: 'Zobraziť detaily',
  settings: 'Nastavenia',

  // Error messages
  e404Title: 'Stránka nebola nájdená',
  e404Description: 'Požadovaná stránka nebola nájdená!<br/>Kliknite na tlačidlo pre presmerovanie na zoznam vašich prepojení.',
  e404Button: 'Zoznam prepojení',

  e500Title: 'Vnútorná chyba servera',
  e500Description: 'Vnútorná chyba servera!<br/>Kliknite na tlačidlo pre presmerovanie na zoznam vašich prepojení.',
  e500Button: 'Zoznam prepojení',

  e403Title: 'Zakázaný prístup',
  e403Description: 'Nemáte právo na otvorenie zvolenej stránky!<br/>Kliknite na tlačidlo pre presmerovanie na zoznam Vašich prepojení.',
  e403Button: 'Zoznam prepojení',

  // Logout according to eco-system
  logoutTitle: 'Odkiaľ sa chcete odhlásiť?',
  logoutDescription: '<p><strong>Pozor, odhlásením budú všetky aplikácie eco-systému zatvorené. Vaše neuložené práce v iných aplikáciách eco-systému môžete tiež stratiť.</strong></p><p>Ak sa odhlásite, budete odhlásený zo všetkých aplikácií vášho finančného eco-systému na tomto zariadení.</p><p>Pomocou tlačidla <strong>"Odhlásiť sa všade"</strong> budete odhlásený <strong>na všetkých zariadeniach</strong>, kde ste sa prihlásili do vášho finančného eco-systému.</p>',
  logoutBtnAll: 'Odhlásiť sa všade',
  logoutBtnEco: 'Odhlásiť sa',
  logoutCouldNotRewoke: 'Nebolo možné sa odhlásiť zo systému Positive Access Point, skúste to prosím znovu!',

  // Navigation
  navAdmin: 'Administrácia',
  navAdminApplicationSetup: 'Správa prepojení',
  navAdminAccountTypeClassifier: 'Práva podľa vstupov',
  navAdminExports: 'Exporty',
  navMyApps: 'Moje prepojenia',
  navMyProfile: 'Môj profil',
  navLogout: 'Odhlásiť sa',


};
