import Vue from 'vue';
import Vuex from 'vuex';
import createMultiTabState from 'vuex-multi-tab-state';

Vue.use(Vuex);

export default new Vuex.Store({

  plugins: [
    createMultiTabState({
      statesPaths: [
        'layout',
        'language',

        'authTokenData',
        'authTokenIsRefreshing',

        'oAuthState',
        'codeVerifier',
        'codeChallenge',

        'isAdmin',
        'improvements',
        'branding',

        'apiErrors',
        'apiErrorPointer',

        'command'
      ],
    }),
  ],

  state: {
    // Permanent parameters on all tabs same value
    layout: 'default',
    language: 'sk',

    authTokenData: null,
    authTokenIsRefreshing: false,

    oAuthState: false,
    codeVerifier: false,
    codeChallenge: false,

    isAdmin: false,
    improvements: false,
    branding: false,

    apiErrors: [],
    apiErrorPointer: 0,

    command: false,

    // Parameter can have different value on all tabs
    loggedOutPopup: false,
    temporaryAuthError: false,
  },

  mutations: {
    set_language(state, payload) {
      state.language = payload;
      window.localStorage.setItem('language', payload.lang);
      payload.i18n.locale = payload.lang;
      state.language = payload.lang;
    },

    set_authTokenData(state, payload) {
      if (payload && payload.expires_in !== undefined) {
        payload.expires_at = Math.floor(Date.now() / 1000) + payload.expires_in;
      }

      state.authTokenData = payload;
    },

    set_authTokenIsRefreshing(state, payload) {
      state.authTokenIsRefreshing = payload;
    },

    set_oAuthState(state, payload) {
      state.oAuthState = payload;
    },

    set_codeVerifier(state, payload) {
      state.codeVerifier = payload;
    },

    set_codeChallenge(state, payload) {
      state.codeChallenge = payload;
    },

    set_isAdmin(state, payload) {
      state.isAdmin = payload;
    },

    set_improvements(state, payload) {
      state.improvements = payload;
    },

    set_branding(state, payload) {
      state.branding = payload;
    },

    set_command(state, payload) {
      state.command = payload;
    },

    set_apiError(state, payload) {

      // var today = new Date();
      // var currentDay = today.getFullYear() + '-' + ("0"+(today.getMonth()+1)).slice(-2) + '-' + ("0"+today.getDate()).slice(-2);
      // var currentTime = ("0"+today.getFullYear()).slice(-2) + ':' + ("0"+(today.getMonth()+1)).slice(-2) + ':' + ("0"+today.getDate()).slice(-2);
      // var text = currentDay + ' ' + currentTime + ': ' + payload;

      var text = payload;

      if (state.apiErrors.length > 20) {
        var apiErrors = state.apiErrors.slice((state.apiErrorPointer));
        apiErrors.push(text);

        state.apiErrors = apiErrors;
        state.apiErrorPointer = 0;
      } else {
        state.apiErrors.push(text);
      }
    },

    increment_apiErrorPointer(state) {
      state.apiErrorPointer++;
    },

    set_loggedOutPopup(state) {
      state.loggedOutPopup = true;
    },

    set_temporaryAuthError(state) {
      state.temporaryAuthError = true;
    },
  },

  getters: {
    lang(state) {
      return state.language;
    },

    authTokenData(state) {
      return state.authTokenData;
    },

    authTokenIsRefreshing(state) {
      return state.authTokenIsRefreshing;
    },

    authAccessToken(state) {
      return (state.authTokenData != null) ? state.authTokenData.access_token : null;
    },

    oAuthState(state) {
      return (state.oAuthState != null) ? state.oAuthState : null;
    },

    codeVerifier(state) {
      return state.codeVerifier;
    },

    codeChallenge(state) {
      return state.codeChallenge;
    },

    isAdmin(state) {
      return state.isAdmin;
    },

    improvements(state) {
      return state.improvements;
    },

    branding(state) {
      return state.branding;
    },

    hasImprovement: (state) => (improvementName) => {
      return (state.improvements != undefined && state.improvements[improvementName] != undefined && state.improvements[improvementName] === true);
    },

    loggedOutPopup(state) {
      return state.loggedOutPopup;
    },

    temporaryAuthError(state) {
      return state.temporaryAuthError;
    },

    command(state) {
      return state.command;
    },
  },

  actions: {
    get_apiError({ commit, state }) {
      var res = state.apiErrors[state.apiErrorPointer];

      if (res) {
        commit('increment_apiErrorPointer')
        return res;
      }

      return false;
    },
  }

});
