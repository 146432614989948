<template>

  <v-app>
    <!--transition name="fade"-->
      <router-view v-if="this.appVersion || this.authPage"></router-view>
    <!--/transition-->

    <v-main v-else>
      <v-container fluid fill-height class="overlay-loader">

        <v-container>
          <v-layout justify-center>
            <v-flex xs12 sm8 md4 class="text-center">
                <v-img :src="require('@/assets/ap-logo-white.svg')" aspect-ratio="1" max-height="130" :alt="$t('com.appLogoAlt')" contain class="mb-10" style="z-index: 5;"></v-img>

                <div v-if="loadingError" class="text-center">
                  <strong>{{ $i18n.t('com.appRunError') }}</strong>

                  <v-card-actions class="justify-center mt-5">
                    <v-btn type="submit" color="primary" @click="reloadApp()">{{ $t('com.errorLoginRetryBtn') }}</v-btn>
                  </v-card-actions>
                </div>

                <div v-else>
                  <v-img :src="require('@/assets/three-dots.svg')" max-width="30%" max-height="100" :alt="$t('com.loadingAlt')" contain style="margin: 1em auto 2em auto;"></v-img>
                  <strong>{{ $i18n.t('com.appRunInProgress') }}</strong>
                </div>

            </v-flex>
          </v-layout>
        </v-container>

      </v-container>
    </v-main>

  </v-app>

</template>

<style lang="scss">

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s
  }
  .fade-enter, .fade-leave-active {
    opacity: 0
  }

  .container {
    max-width: 1264px !important;
  }

  ._vue-flash-msg-body {
    z-index: 9999;
    ._vue-flash-msg-body__content {
      padding: .5em 0;
    }
    p {
      margin-bottom: 0 !important;
    }
  }

  .overlay-loader {
    z-index: 9999;
    background: #f0f0f0;
    margin: 0;
    max-width: initial !important;
  }

</style>

<script>

  export default {

    mounted() {
      //setTimeout(this.runVersionChecker, 1000);
    },

    data: () => ({
      apiErrorProgress: false,

      versionControl: false,

      loadingError: false,
      appVersion: false,
      authPage: true,
    }),

    metaInfo() {
      return {
        // Result: My Page Title - appTitle
        // My Page Title is set in childs
        titleTemplate: '%s | ' + this.$i18n.t('com.appTitle'),

        // Define meta tags here.
        meta: [
          {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
          {'name': 'viewport', content: 'width=device-width, initial-scale=1'},
          {'name': 'description', content: this.$i18n.t('com.appDescription')}
        ]
      }
    },

    watch: {
        '$store.state.apiErrors': function () {

          // Message displaying is in progress
          if (this.apiErrorProgress) {
            return;
          }

          // If message displaying is not is progress, run it
          this.apiErrorProgress = true;
          this.displayApiErrors();
        }
    },

    methods: {

      /*getUser() {
        this.$api.get('user').then(response => {
          if (response.data && response.data.success) {
          }
        }).catch(() => {
          this.loadingError = true;
        });
      },*/

      reloadApp() {
        window.location.reload();
      },

      runVersionChecker() {
        this.getVersion();

        clearInterval(this.versionControl);
        this.versionControl = setInterval(this.getVersion, 30*1000);
      },

      getVersion() {
        if (this.$router.currentRoute.name == 'login' || this.$router.currentRoute.name == 'callback') {
          this.authPage = true;
          return;
        }

        this.authPage = false;

        this.$api.get('AccessPointVersion').then(response => {
          if (response.data && response.data.success) {
            if (this.appVersion == false) {
              this.appVersion = response.data.application.version;
            }

            if (this.appVersion != false && this.appVersion != response.data.application.version) {
              this.reloadApp();
            }
          }
        }).catch(() => {
          this.loadingError = true;
        });
      },

      async displayApiErrors() {
        await this.$store.dispatch("get_apiError").then(response => {
          if (response) {
            this.flashMessage.show({
              status: 'error',
              title: response,
              time: '3000',
            });

            setTimeout(this.displayApiErrors, 4500)
          } else {
            this.apiErrorProgress = false;
          }
        });
      },

    }

  };

</script>
