import appConfig from '@/config/app';
//import auth from '@/plugins/auth';
import store from '@/store/main';
import axios from 'axios';
import {clientID} from '@/config/oauth';

import i18n from '@/lang/lang';

const checkTokenInterval = 300; // in seconds

const ajax = axios.create({
  baseURL: appConfig.apiURL
});

ajax.CancelToken = axios.CancelToken
ajax.isCancel = axios.isCancel
ajax.disableApiErrorMessages = false;

/*
* The interceptor here ensures that we check for the token in local storage every time an ajax request is made
*/
ajax.interceptors.request.use(
  (config) => {
    let token = store.getters.authTokenData;

    if (token) {
      config.headers['Authorization'] = `${token.token_type} ${ token.access_token }`;
    }

    config.params = config.params || {};
    config.params['lang'] = store.getters.lang;

    return config
  },

  (error) => {
    return Promise.reject(error)
  }
);

ajax.interceptors.response.use(
  (response) => {
    // Reset number of retries
    // trycount = 0;

    // Successfull REST do something with response
    return response;
  },

  (error) => {

    if (axios.isCancel(error)) {
      throw error;
    }

    const originalRequest = error.config;

    if ((error.response.status == 401 || (error.response.data && error.response.data.errorType == 'authenticationFailed')) && !originalRequest._retry) {

      // The token is actually refreshing, wait some time and repeat the request with the new token
      if (parseInt(store.getters.authTokenIsRefreshing) > (Math.floor(Date.now()/1000) - 5)) {
        return new Promise((resolve) => {
          setTimeout(() => {
            let token = store.getters.authTokenData;

            if (token) {
              originalRequest.headers['Authorization'] = `${token.token_type} ${ token.access_token }`;
              originalRequest.params['retry'] = 1;
            }

            resolve(axios(originalRequest));
          }, 3000);
        });
      }

      originalRequest._retry = true;

      const authTokenData = store.getters.authTokenData;

      store.commit('set_authTokenIsRefreshing', Math.floor(Date.now()/1000));

      return axios.post(appConfig.loginURL + 'oauth/token', {
        grant_type: 'refresh_token',
        refresh_token: authTokenData.refresh_token,
        client_id: clientID,
        scope: '',
      }).then(response => {
        store.commit('set_authTokenData', response.data);
        store.commit('set_authTokenIsRefreshing', 0);

        originalRequest.headers['Authorization'] = 'Bearer ' + response.data.access_token;
        originalRequest.params['retry'] = 1;

        return axios(originalRequest);
      }).catch(() => {
        ajax.destroyToken();
        store.commit('set_apiError', i18n.t('error.tokenRefreshFailed'));
        setTimeout(() => {
          window.location = "/login";
        }, 3000);
        return;
      });
    }

    // Return any error which is not due to authentication back to the calling service
    if (originalRequest.disableErrorManager == undefined || !originalRequest.disableErrorManager) {
      ajax.errorManager(error);
    }

    throw error;
  }
);


// Return any error which is not due to authentication back to the calling service
ajax.errorManager = (error) => {
  // User was logged out
  if (!error.response.data.success && error.response.data.errorType == 'notLoggedIn') {
    store.commit('set_loggedOutPopup', true);
    return;
  }
  // Temporary authentification error
  else if (!error.response.data.success && error.response.data.errorType == 'getUserDataFailed') {
    store.commit('set_temporaryAuthError', true);
    return;
  }

  // Manage other error messages
  if (!ajax.disableApiErrorMessages) {
    if (error.response.data.success == false) {
      if (error.response.data.errorType === '') {
        store.commit('set_apiError', error.response.data.msg);
      }
      else {
        var i18nErrorKey = 'error.' + error.response.data.errorType;
        var i18nError = i18n.t('error.' + error.response.data.errorType);

        if (i18nErrorKey == i18nError) {
          store.commit('set_apiError', ((error.response.data.msg && error.response.data.msg != '') ? error.response.data.msg : i18n.t('error.unknownError')));
        } else {
          store.commit('set_apiError', i18nError);
        }
      }
    }
  }
}


// Token refreshing was not possible, logout user
ajax.destroyToken = () => {
  store.commit('set_authTokenData', null);
  store.commit('set_authTokenIsRefreshing', 0);
}


// Refresh the access token
ajax.refreshToken = () => {
  // Get the actual token information
  var authTokenData = store.getters.authTokenData;

  // Access token is not set -> user is not logged in
  if (authTokenData == undefined || !authTokenData) {
    return false;
  }

  // Refresh token can be NaN, then the comparison will return false (in case of NaN run the refresh too)
  var refreshTokenTime = parseInt(store.getters.authTokenIsRefreshing);

  // If the token is refreshing do not run a next refresh process
  if (!refreshTokenTime || refreshTokenTime < (Math.floor(Date.now()/1000) - 5)) {

    // Set refreshing status
    store.commit('set_authTokenIsRefreshing', Math.floor(Date.now()/1000));

    // Do not make refresh if we have valid access token
    if (authTokenData && authTokenData.expires_at > (Math.floor(Date.now() / 1000) + checkTokenInterval + 10)) {
      return false;
    }

    // Let's refresh
    if (authTokenData && authTokenData.refresh_token) {
      axios.post(appConfig.loginURL + 'oauth/token', {
        grant_type: 'refresh_token',
        refresh_token: authTokenData.refresh_token,
        client_id: clientID,
        scope: '',
      }).then((response) => {
        // Save the new token and release the token refresh blocking
        store.commit('set_authTokenData', response.data);
        store.commit('set_authTokenIsRefreshing', 0);
      })
      .catch(() => {
        ajax.destroyToken();
        store.commit('set_apiError', i18n.t('error.tokenRefreshFailed'));
        setTimeout(() => {
          window.location = "/login";
        }, 3000);
      });
    } else {
      // Refresh token is not available
      window.location = "/login";
    }

    return true;
  }

  return false;
}


// Set interval for token checking
setInterval(() => {
  ajax.refreshToken();
}, checkTokenInterval*1000);


export default ajax
