const env = 'production';

const apiVersion = 'v1';

const frontendURLs =  {
    local : 'http://localhost:8080/',
    test : 'http://localhost:8080/',
    production : 'https://ap.positive.sk/',
};

const backendURLs =  {
    local : 'http://ap.local/',
    test : 'https://authority.positive.sk/',
    production : 'https://authority.positive.sk/',
};

const loginURLs =  {
    local: 'http://ap.local/',
    test: 'https://authority.positive.sk/',
    production: 'https://authority.positive.sk/',
};

const logoutURLs =  {
    local : 'http://ap.local/logout',
    test : 'https://authority.positive.sk/logout',
    production : 'https://authority.positive.sk/logout',
};

const customerDesign = {
    vuetifyColors: {
        primary: '#f16721',
        secondary: '#ffffff',
        accent: '#82b1ff',
        error: '#d9534f',
        info: '#5bc0de',
        success: '#26b99a',
        warning: '#f0ad4e',
    },
}

export default {
    // Environment mode
    env: env,

    // URLs of this application
    frontendURL: frontendURLs[env],
    backendURL: backendURLs[env],
    logoutURL: logoutURLs[env],
    loginURL: loginURLs[env],

    apiURL: backendURLs[env] + 'api/' + apiVersion + '/',

    // Inactivity logout (in seconds) -> at least 60 sec
    inactivityTimer: 3600,

    customerDesign: customerDesign,
};
