export default {

  // Admin page titles
  applicationSetup: 'Správa prepojení',
  accountTypeClassifier: 'Práva na základe vstupu',
  exportSetup: 'Správa exportov',

  // Account Type Classifier page
  accountTypeClassifierSaved: 'Priradenie používateľských typov bolo úspešne uložené!',
  accountTypeColumn: 'Skupina, pozícia',
  accountTypesSort: 'Zoradiť',
  accountTypesAddRow: 'Pridať riadok',
  accountTypesUpdate: 'Uložiť zmeny',
  accountTypeParameterName: 'Na základe parametra',
  accountTypeParameterValue: 'Hodnota parametra',
  'parameterName-groupId': 'ID skupiny',
  'parameterName-nbsNumber': 'NBS reg. č.',
  'parameterName-employeeNumber': 'Osobné číslo',
  'parameterName-default': 'Predvolené',

  // Account Type invalid data in form
  accountTypesNotValidTitle: 'Chyba v nastaveniach',
  accountTypesNotValidEmptyOrNula: 'Hodnota zvoleného parametra nesmie byť prázdny reťazec alebo "0"!',
  accountTypesNotValidUniqueRule: 'Nastavili ste pravidlo, ktoré je duplicitné!',
  accountTypesNotValidIllegalCharacters: 'Používate nepovolené znaky v hodnote premennej!',
  accountTypesNotValidMutuallyExclusive: 'Nastavili ste oprávnenia, ktoré sú navzájom vylučujúce!',

  accountTypesNoCapps: 'Nemáte nastavené žiadne prepojenia, nie je možné uložiť práva.',
  accountTypeParametersInfo: 'Parametre, ktoré majú označený riadok modrou farbou, majú vyššiu prioritu.<br />Ak sa na práva používateľa vzťahuje viacero predpisov, budú sa uplatňovať podľa priority!',
  accountTypesPermissionInfoTitle: 'Popis možných oprávnení',

  // Capp table
  cappId: 'ID',
  appLogo: 'Logo',
  cappName: 'Názov prepojenia',
  appCompanyName: 'Meno partnera',
  activeCapps: 'Aktivovať',
  actions: 'Operácie',
  downloadConfig: 'Stihanuť konfiguračný súbor',
  createChildren: 'Pridať pod-aplikáciu',
  createdFromParent: 'Vytvorené z {capp}',

  // Download configuration file
  confirmDownloadTitle: 'Stiahnutie kľúča',
  confirmDownloadDescription: '<p>Naozaj si chcete stiahnuť konfiguračný súbor?</p><p>Konfiguračný súbor obsahuje prihlasovacie kľúče Vašej spoločnosti do partnerského systému! Tento súbor odovzdajte partnerovi a následne ho vymažte.</p><p>V prípade, ak sa tento súbor dostane do rúk tretej osobe, okamžite vymažte toto prepojenie a nakonfigurujte si ho znovu.</p>',
  confirmDownloadButton: 'Určite stiahnuť',

  // Delete capp
  deleteCappTitle: 'Odstrániť prepojenie',
  deleteCappDescription: '<p>Naozaj chcete odstrániť prepojenie?</p><p>V prípade oAuth2 prepojenia, budete musieť znovu odoslať konfiguračný súbor Vášmu partnerovi a následne Vám Váš partner bude musieť schváliť nový prístup.</p>',
  confirmDeleteButton: 'Určite odstrániť',

  // Delete capp
  createChildrenTitle: 'Vytvoriť podradené prepojenie',
  createChildrenDescription: '<p>Naozaj chcete vytvoriť ďalšie prepojenie na základe vybratého oAuth2 klienta?</p><p>Pomocou tejto funkcie sa budete mocť prihlásiť do viacerých aplikácií partnera pomocou jedného oAuth2 klienta.</p>',
  confirmCreateChildren: 'Určite vytvoriť',

  // Aplication activation process
  activationProcessAtPartner: 'Proces aktivácie služby',

  youDontHaveApps: 'Nemáte nakonfigurované žiadne prepojenia',

  confirmDeleteMsg: 'Naozaj chcete vymazať vybratú položku?',
  errorInCommunication: 'Chyba počas komunikácie so serverom. Skúste znovu!',

  // Admin homepage
  accessibleApps: 'Nastavené prepojenia',

  // Form in dialog
  newCapp: 'Pridať nové prepojenie',
  editCapp: 'Zmeniť prepojenie',
  productSelectLabel: 'Partnerský systém',

  accessTypeOff: 'Vypnuté obmedzenia',
  accessTypeBlacklist: 'Týmto zakázať: (blacklist)',
  accessTypeWhitelist: 'Týmto povoliť: (whitelist)',
  accessItemList: 'Zoznam "NBS čísel" agentov',

  'appForm-sectorsSelect': 'Povoliť pre sektory',
  'appForm-sectorsPlaceholder': 'Prístup povolený pre všetky sektory',
  'appForm-identifierFormat': 'Formát identifikátora',

  'appSector-insuranceReinsurance': 'Poistenia alebo zaistenia',
  'appSector-capitalMarket': 'Kapitálového trhu',
  'appSector-acceptanceDeposits': 'Prijímania vkladov',
  'appSector-grantingLoans': 'Poskytovania úverov, úverov na bývanie a spotrebiteľských úverov',
  'appSector-supplementaryPensionSavings': 'Doplnkového dôchodkového sporenia',
  'appSector-oldAgePensionSavings': 'Starobného dôchodkového sporenia',

  missingConfigFile: 'Nebolo možné stiahnuť konfiguračný súbor! Skúste znovu!',

  // Export page
  exportNeedingCapps: 'Prepojenia s exportom',

  exportRootUser: 'Admin konto',
  exportTest: 'Test mode',
  exportEnabled: 'Status',

  exportListTitleFor: 'História exportov pre',
  exportLogDetailsTitle: 'Chyby a upozornenia exportu',
  exportDatapackTitle: 'Prijaté údaje',

  exportProductionExport: 'Ostré exporty',
  exportTestExport: 'Testovacie exporty',

  exportCappId: 'Id prepojenia',
  exportExportLogId: 'Id exportu',
  exportStatus: 'Status',
  exportStatusSuccess: 'Úspešné',
  exportStatusError: 'Chyba',
  exportSent: 'Odoslané partnerovi',
  exportIgnoredUsers: 'Ignorované',
  exportAllowedUsers: 'Exportované',
  exportCreatedAt: 'Exportované',

  exportShowAllowedUsers: 'Úspešne exportovaní používatelia',
  exportShowIgnoredUsers: 'Ignorovaní používatelia',
  exportShowLogDetails: 'Ukáž detaily exportu',
  exportShowDatapack: 'Ukáž prijaté dáta',

  exportAllowedUsersTitle: 'Zoznam úspešne exportovaných používateľov',
  exportIgnoredUsersTitle: 'Zoznam neúspešne exportovaných používateľov',

  exportSettingsTitle: 'Nastavenia exportu pre {name}',

  exportSettingsInfo: 'Do exportu budú zahrnutí iba tí používatelia, ktorí splnia všetky kritéria k exportu. To znamená, že majú vyplnené všetky povinné údaje a majú právo na prístup do partnerského systému!',
  exportSettingsEnabled: 'Povoliť export',
  exportSettingsEnabledHint: 'Povoliť export používateľov do partnerského systému na dennej báze.',
  exportSettingsTest: 'Testovací režim',
  exportSettingsTestHint: 'V testovacom režime budú všetky vaše údaje spracované, uvidíte úplný zoznam exportovateľných aj ignorovaných používateľov. Údaje nebudú odovzdané partnerskému systému. Táto funkcionalita je určená na testovanie nového prepojenia, alebo pri kritických zmenách práv.',
  exportSettingsRoot: 'Vytvoriť admin účet',
  exportSettingsRootHint: 'Admin účet slúži na vytvorenie účtu pre vašu spoločnosť. Používa sa napr. v prípade, ak vo vašej spoločnosti pracujú administratívni pracovníci, ktorí pristupujú do partnerského systému pod názvom spoločnosti a nie pod svojím vlastným menom.',
  exportSettingsStructure: 'Preskočiť ignorovaných používateľov',
  exportSettingsStructureHint: 'V prípade, ak sa používateľ stane ignorovaným z exportu (napr.: chýbajúce údaje, ignorovaný na základe nastavených práv), jeho podštruktúra sa priradí jeho nadriadenému. Ak je toto nastavenie vypnuté, akonáhle sa stane používateľ ignorovaným, celá jeho podštuktúra bude tiež ignorovaná, kód chyby bude "outOfStructure".',

};
