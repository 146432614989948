import Vue from 'vue';
import App from './App.vue';
import router from './router/index';
import store from '@/store/main';
import vuetify from '@/plugins/vuetify';
import FlashMessage from '@smartweb/vue-flash-message';
import auth from '@/plugins/auth';
import api from '@/plugins/api';
import config from '@/config/app';
import * as Sentry from "@sentry/vue";

import "core-js/stable";
import "regenerator-runtime/runtime";
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@fortawesome/fontawesome-free/css/all.css';

import i18n from '@/lang/lang';
import axios from 'axios';
import Meta from 'vue-meta';

Vue.config.productionTip = false;

// Show flash messages for 10 seconds
Vue.use(FlashMessage, {time: 10000});
Vue.use(Meta);

// Load main app configuration
Vue.prototype.$config = config;

// Add ajax library and authentification library to Vue app
Vue.prototype.$http = axios;
Vue.prototype.$auth = auth;

// Initialize API
Vue.prototype.$api = api;

Sentry.init({
  Vue,
  dsn: "https://ecf4697ba9f3ec1d02cd82715e92f245@sentry.positive.sk/9",
  environment: config.env,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: [config.frontendURL, /^\//],
    }),
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Initialize Vue app
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
