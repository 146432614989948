import appConfig from '@/config/app';

const clientIDs = {
  local : 'da2693ab-ed17-46f3-afd8-cbf9ea714439',
  test : 'da2693ab-ed17-46f3-afd8-cbf9ea714439',
  production : 'da2693ab-ed17-46f3-afd8-cbf9ea714439',
};

const redirectURL =  {
  local : 'http://localhost:8080/auth/callback',
  test : 'http://localhost:8080/auth/callback',
  production : 'https://ap.positive.sk/auth/callback',
};

const clientScopes = {
  local : '',
  test : '',
  production : '',
};

export const clientID = clientIDs[appConfig.env];
export const clientRedirect = redirectURL[appConfig.env];
export const scopes = clientScopes[appConfig.env];
