export const publicRoute = [
  { path: "*", component: () => import("@/views/error/NotFound.vue") },
  { path: "/login", redirect: "/auth/login" },
  { path: "/callback", redirect: "/auth/callback" },
  {
    path: "/auth",
    component: () => import('@/theme/layouts/LoginLayout.vue'),
    meta: { bodyClass: 'login' },
    redirect: "/auth/login",
    hidden: true,
    children: [
      {
        path: "/auth/login",
        name: "login",
        component: () => import(/* webpackChunkName: "login" */ "@/views/auth/Login.vue")
      },
      {
        path: '/auth/callback',
        name: 'callback',
        component: () => import('@/views/auth/Callback.vue'),
      }
    ]
  },

  {
    path: "/404",
    name: "404",
    component: () => import(/* webpackChunkName: "errors-404" */ "@/views/error/NotFound.vue")
  },

  {
    path: "/500",
    name: "500",
    component: () => import(/* webpackChunkName: "errors-500" */ "@/views/error/Error.vue")
  },

  {
    path: "/403",
    name: "Forbidden",
    component: () => import(/* webpackChunkName: "error-403" */ "@/views/error/Deny.vue")
  }
]

export const protectedRoute = [
  // Simple user
  {
    path: "/",
    component: () => import('@/theme/layouts/PapLayout.vue'),
    redirect: "/moje-aplikacie",
    meta: { bodyClass: 'default', group: "cms", icon: "" },
    // beforeEnter: requireAuth,
    children: [
      {
        path: "/moje-aplikacie",
        name: "myApps",
        //redirect: "/admin/moje-aplikacie",
        component: () => import(/* webpackChunkName: "table" */ "@/views/dashboard/MyApps.vue")
      },
      {
        path: "/moj-profile",
        name: "myProfile",
        // alias: "abc",
        component: () => import(/* webpackChunkName: "table" */ "@/views/profile/MyProfile.vue")
      }
    ]
  },

  // Administrator
  {
    path: "/admin",
    name: "admin",
    component: () => import('@/theme/layouts/PapLayout.vue'),
    meta: { bodyClass: 'admin', group: "admin", icon: "" },
    // beforeEnter: requireAuth,
    redirect: "/admin/moje-aplikacie",
    children: [
      {
        path: "/admin/moje-aplikacie",
        name: "adminApplicationSetup",
        component: () => import(/* webpackChunkName: "dashboard" */ "@/views/admin/ApplicationSetup.vue")
      },
      {
        path: "/admin/account-type-classifier",
        name: "adminAccountTypeClassifier",
        component: () => import(/* webpackChunkName: "dashboard" */ "@/views/admin/AccountTypeClassifier.vue")
      },
      {
        path: "/admin/exports",
        name: "adminExports",
        component: () => import(/* webpackChunkName: "dashboard" */ "@/views/admin/Exports.vue")
      }
    ]
  }
];
