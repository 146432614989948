import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from '@/store/main';

Vue.use(VueI18n);

const fallbackLang = 'sk';
var initLang = store.getters.lang || window.navigator.language.substring(0,2) || 'sk';

// Ready translated locale messages
const messages = {
  en: {
    com: require('@/lang/en/com').default,
  },
  sk: {
    com: require('@/lang/sk/com').default,
    admin: require('@/lang/sk/admin').default,
  },
};

if (!(initLang in messages)) {
  initLang = fallbackLang;
}

// Create VueI18n instance with options
export default new VueI18n({
  locale: initLang, // set locale
  fallbackLocale: fallbackLang,
  messages, // set locale messages
});
