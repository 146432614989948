import Vue from "vue"
import Router from "vue-router"
import { publicRoute, protectedRoute } from "./config"
import store from '@/store/main';
import VueBodyClass from 'vue-body-class';

// import NProgress from "nprogress"
// import "nprogress/nprogress.css"

const routes = publicRoute.concat(protectedRoute)

Vue.use(Router)

const router = new Router({
  mode: "history",
  linkActiveClass: "active",
  base: process.env.BASE_URL,
  routes: routes
})

const vueBodyClass = new VueBodyClass(routes);

// router gards
router.beforeEach((to, from, next) => {
  // NProgress.start()
  vueBodyClass.guard(to, next)

  requireAuth(to, from, next);

})

function requireAuth (to, from, next) {

  // redirect to login page if not logged in and trying to access a restricted page
  let accessToken = store.getters.authAccessToken;

  let isAdmin = store.getters.isAdmin;

  const publicPages = ['/auth/login', '/auth/callback'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = accessToken && accessToken != '';

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  //check admin
  if(loggedIn && !isAdmin && to.path.includes("admin")) {
    return next('/moje-aplikacie')
  }

  //auth route is authenticated
  next()
}

/*router.afterEach((to, from) => {
  // NProgress.done()
})*/

export default router
