import appConfig from '@/config/app';
import { clientID, clientRedirect, scopes} from '@/config/oauth';
import ClientOAuth2 from 'client-oauth2';
import store from '@/store/main';

/* Helper functions */

function generateRandomString(length) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}

async function SHA256Challenge(codeVerifier) {
    const msgUint8 = new TextEncoder().encode(codeVerifier);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
    return base64URL(String.fromCharCode.apply(null, new Uint8Array(hashBuffer)));
}

// RFC 4648 mentions other alphabets, such as the "URL and Filename safe" Base 64 Alphabet, where + and / are replaced with - and _.
function base64URL(string) {
    return window.btoa(string).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

/* The login process */
async function runLogin() {

    var state = generateRandomString(32);
    var codeVerifier = generateRandomString(128);
    var codeChallenge = await SHA256Challenge(codeVerifier);

    store.commit('set_oAuthState', state);
    store.commit('set_codeVerifier', codeVerifier);
    store.commit('set_codeChallenge', codeChallenge);

    return new ClientOAuth2({
        authorizationUri: appConfig.loginURL.replace(/^\/|\/$/g, '') + '/oauth/authorize',
        redirectUri: clientRedirect,
        clientId: clientID,
        scopes: scopes,
        state: state,
        query: {
            response_type: 'code',
            code_challenge: codeChallenge,
            code_challenge_method: 'S256'
        }
    });
}

export default {
    runLogin: runLogin
};
